<template>
  <MDBNavbarNav class="m-0 mb-2 mb-lg-0 flex-row">
    <MDBNavbarItem to="/">Home</MDBNavbarItem>
    <MDBNavbarItem to="/policies">Policies</MDBNavbarItem>
    <MDBNavbarItem v-show="!loggedIn" to="/sign-in">Login</MDBNavbarItem>
    <MDBNavbarItem v-show="loggedIn" to="/account">Account</MDBNavbarItem>
    <MDBNavbarItem v-show="isVendor" to="/vendor">Vendor Dashboard</MDBNavbarItem>
    <MDBNavbarItem v-show="isDriver" to="/driver">Driver Dashboard</MDBNavbarItem>
    <MDBNavbarItem v-show="isAdmin" to="/admin">Admin Dashboard</MDBNavbarItem>
    <ButtonSignOut v-show="loggedIn" size="sm" />
    <MDBNavbarItem v-show="isCustomer" to="/order/cart">
      <Icon name="tabler:shopping-cart" class="text-white" />
      <span class="visually-hidden">Cart</span>
      <MDBBadge badge="primary" pill notification>
        {{ cart.length }}
      </MDBBadge>
    </MDBNavbarItem>
  </MDBNavbarNav>
</template>

<script setup lang="ts">
import { MDBNavbarNav, MDBNavbarItem, MDBBadge } from "mdb-vue-ui-kit";

const { loggedIn, isVendor, isAdmin, isCustomer, isDriver } = storeToRefs(useUser());
const { cart } = storeToRefs(useCart());
</script>

<style lang="scss">
.nav-item > .nav-link {
  padding: var(--mdb-nav-link-padding-y);
}
</style>
