<template>
  <MDBContainer>
    <ClientOnly>
      <WarningBannerDemo v-if="demo" />
      <WarningBannerAlpha v-else />
    </ClientOnly>
    <Header />
    <MDBRow class="main-background">
      <slot />
    </MDBRow>
    <Footer />
    <CookieConsent />
    <LazyGlobalToast />
  </MDBContainer>
</template>

<script setup lang="ts">
import { MDBContainer, MDBRow } from "mdb-vue-ui-kit";
const demo = isDemo();
</script>
