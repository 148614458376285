<template>
  <MDBRow tag="header" class="d-flex justify-content-around">
    <MDBNavbar expand="lg" dark container>
      <MDBNavbarBrand href="/">
        <Logo />
      </MDBNavbarBrand>
      <ClientOnly>
        <span class="navbar-toggler" role="button" @click="collapse1 = !collapse1">
          <Icon name="gg:menu" size="1.5rem" />
        </span>

        <MDBCollapse v-model="collapse1">
          <NavBar />
        </MDBCollapse>
      </ClientOnly>
    </MDBNavbar>
  </MDBRow>
</template>

<script setup lang="ts">
import { MDBRow, MDBNavbar, MDBNavbarBrand, MDBCollapse } from "mdb-vue-ui-kit";
const collapse1 = ref(false);
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as v;

header {
  background-color: v.$dark-grey;
}

.push-left {
  left: 50rem !important;
}
</style>
